.card-section-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: clip;

  display: flex;
  flex-direction: column;
  align-items: center;  
}

.card-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  position: relative;
}

.cross-icon {  
  fill: #c4b4d1;
  width: 1.2rem;
  height: 1.2rem;
}

.cross-holder {
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  top: .3rem;
}

.card-section-title {
  color: #c4b4d1;
  font-size: 1.5em;  
}

.player-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;   
  margin-left: -.75rem; 
  margin-right: -.75rem;

  background-color: black;
  padding-bottom: 20rem;
}