.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.8);
  user-select: none;
}

.popup-content-image {
  background-color: rgba(68, 34, 34, 0.4);
  border: 3px double rgba(136, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 2rem;

  position: relative;
}
.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
}

.cross-holder {
  margin-left: auto;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.popup-title {
  font-size: 3em;  
  color: white;  
  font-family: 'TitilliumWeb-Bold';    
}

.message-text-top {
  font-size: 1.5em;  
  color: white;    
  
  text-align: center;
}

.message-text {
  font-size: 1.5em;  
  color: white;    
  padding: 2rem;
  text-align: center;
}

.button-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  color: white;
  text-decoration: underline;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 1em;
}

.return-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 4rem .5rem 4rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'TitilliumWeb-Bold';
  margin-top: 5rem;
}

.return-button:hover {
  background-color: #6cc0ee;
}