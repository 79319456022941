.main-content {  
  display: flex;
  flex-direction: column;  
}

.top-nav-content {
  display: flex;
  flex-direction: column;

  position: relative;
}

.fleet-holder {
  display: flex;
  flex-direction: column;  
  align-items: center;    
  color: #c4b4d1;  
  font-size: 1em;
  
  margin-top: 1rem;
}

.fleet-top {  
  color: #c4b4d1;  
  font-size: 1.5em;
  
  width: 100%;
  text-align: center;  
  margin-bottom: .5rem;

  display: flex;
  flex-direction: column;
}

.ship-section-wrapper { 
  display: flex;
  flex-direction: column;
  align-items: center;  
}

.player-fleet {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;   
  width: 100%;
  margin-right: .75rem;
}
