.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.9);
  user-select: none;
}

.popup-content-image {
  background-color: var(--base-text-color-alpha-low);
  border: 3px solid var(--base-text-color-alpha);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  position: relative;
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
  position:absolute;
  right: .5rem;
  top: .5rem;
}

.cross-holder {
  margin-left: auto;
  margin-top: 1rem;
  margin-right: 1rem;
}

.popup-title {
  font-size: 1.5em;  
  color: var(--base-text-color);  
  font-family: 'TitilliumWeb-Bold';    
  text-transform: uppercase;
  padding-left: 2rem;
  padding-right: 2rem;
}

.message-details-row {
  font-size: 1em;  
  color: var(--base-text-color);    
  text-align: left;
  margin-top: .5rem;  
}

.confirm-popup-button-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 1rem;
  width: 100%;
  cursor: pointer;
}

.content-section {
  overflow-y: scroll;
  overflow-x: hidden;
  
  display: flex;
  flex-direction: column; 
  padding: 2rem;
}

.popup-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: 1rem 2rem 1rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: 1em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';  
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.popup-button:hover {
  background-color: #6cc0ee;
}

.popup-button-cancel {  
  background-color: rgba(0,0,0,0);
  border: 1px solid white;
  padding: 0rem 2rem 0rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: 1em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';  
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.popup-button-cancel:hover {
  background-color: #6cc0ee;
}

.react-confirm-alert-overlay {
  background: rgba(0,0,0,0.8) !important;
}

.checkbox-icon {  
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
  fill: #c4b4d1;
}

.powerup-section {
  display: flex;
  flex-direction: row;
}

.powerup-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: .5rem;
  margin-right: 2rem;
}

.powerup-name {
  font-size: 1em;  
  color: #c5b0d9;    
  text-align: center;  
}

.powerup-image {
  width: 3rem;
  height: 3rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.powerup-button {
  background-color: rgba(113, 7, 116, 0.4);
  border: 1px solid #aa0bae;
  padding: .4rem 2rem .2rem 2rem;
  color: white;  
  cursor: pointer;
  font-weight: 800;  
  font-family: 'TitilliumWeb-Bold';
  height: 2rem;
}

.powerup-button:hover {
  background-color: rgba(113, 7, 116, 0.8);
}

.powerup-checkmark {
  fill: white;
  width: 1rem;
  height: 1rem;
}

.button-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  color: white;
  text-decoration: underline;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 1em;
}