.wrapper {  
  background-color: transparent;  
  color: var(--base-text-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  font-weight: 800;
  cursor: pointer;

  margin-bottom: 2rem;
}

.popup-content-image {
  background-color: rgba(226,157,228,.2);    
  border: 1px solid #5d83d4;

  justify-content: space-between;
  align-items: center;
  width: 22rem;
  height: 22rem;  

  position:relative;
}

.card-holder-common {
  border: 1px solid white;  
  color: black;
}
.card-holder-uncommon {
  border: 1px solid green;  
}
.card-holder-scarce {
  border: 1px solid teal;  
}
.card-holder-rare {
  border: 1px solid blue;  
}
.card-holder-epic {
  border: 1px solid purple;  
}
.card-holder-legendary {
  border: 1px solid orange;  
}
.card-holder-mythic {
  border: 1px solid #ff66cc;  
}
.card-holder-secret {
  border: 1px solid gold;  
}

.owned-count-holder {
  position: absolute;
  bottom: 0;
  left: 0;
    
  width: 4rem;
  height: 2rem;    
  
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.owned-count-text {
  font-weight: 800;
  font-size: 1.5em;
  color: white;
  text-align: center;  
}

.top-row {  
  color: white;
  padding-bottom: .2rem;
  padding-top: .2rem;  
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 1em;  
  font-weight: 400;
}

.faction-hero {  
  position: absolute;
  left: 5px;
  top: 0px;

  width: 2.5rem;
  height: 2.5rem;
}

.faction-villain {
  position: absolute;
  left: 5px;
  top: 0px;

  width: 2.5rem;
  height: 2.5rem;
}

.top-row-common {
  background-color: white;  
  color: black;  
}
.top-row-uncommon {
  background-color: green;    
}
.top-row-scarce {
  background-color: teal;    
}
.top-row-rare {
  background-color: blue;    
}
.top-row-epic {
  background-color: purple;    
}
.top-row-legendary {
  background-color: orange;    
}
.top-row-mythic {
  background-color: #ff66cc;    
  color: black;  
}
.top-row-secret {
  background-color: gold;    
  color: black;  
}

.swarm-icon {
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 0;
  top:0;
}

.find-reward-button {
  background-color: #2c9fde;
  border: 1px solid white;  
  cursor:pointer;
  color: white;
  font-size: 1em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
  width: 4rem; 
  height: 2rem;   

  display: flex;
  align-items: center;
  justify-content: center;
  
  position: absolute;
  right: 0;
  bottom: 0;
}

.bottom-row {
  display: flex;
  flex-direction: row;
  
  margin-top: 1rem;
}

.left-column {
  display: flex;
  flex-direction: column;
  width: 10rem;  
  align-items: center;
}

.ship-image {  
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 10rem;  
}

.card-name {
  font-size: 1.5em;  
  font-weight: 600;
}

.right-side {
  width: 50%;
  margin-left: 1rem;

  display: flex;
  flex-direction: column;
}

.stats-title {
  font-size: 1.5em;
  font-weight: 600;
}

.stats-block {
  display: flex;
  flex-direction: column;  
}

.stat-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: .5rem;
}

.stat-row-small {
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.stat-image {
  width: 2rem;
  height: 2rem;
}


.ship-rarity {  
  font-weight: 800;  
  margin-top: .2rem;
  font-size: .8em;
}

.stat-details {
  font-size: 1.5em;
  margin-left: 1rem;

  display: flex;
  flex-direction: row;
  margin-top: .2rem;
}

.stat-details-small {
  font-size: 1em;
}

.stat-details-small-white {
  font-size: 1em;
  color: white;
}

.stat-details-small-title {  
  font-size: .8em;
}

.stat-details-small-bold {
  font-size: 1.2em;
  display: inline;  
}

.stat-base-text {
  color: white;
  font-size: .8em;
}

.stat-upgrade-text {
  color: lightgreen;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .1rem;
}

.stat-red-text {
  color: red;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.stat-powerup-text {
  color: #5d83d4;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.description-text {
  font-size: 1em;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.tip-text {
  font-family: "TitilliumWeb-Bold" !important;
  font-weight: 800 !important;  
}

.special-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: .5rem;
  margin-right: .5rem;
}

.special-title {
  font-size: 1rem;
  text-decoration: underline;
  font-weight: 600;
  color: var(--base-text-color);
}

.special-description {
  font-size: 1rem;  
  color: white;
}
