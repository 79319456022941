
.leaderboard-top-row {
  display: flex;
  flex-direction: column;
  width: 100%;  
  align-items: center;
}

.details-block {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
  margin-top: 1rem;  
  margin-bottom: 1rem;
  
  border: 4px solid #c4b4d1;
  border-radius: .1rem;
  width: 90%;
}

.details-column {
  display: flex;
  flex-direction: column;
}

@media (orientation: portrait) {
  .details-block {  
    flex-direction: column;
    width: 80%;
  }

  .details-column {
    margin-top: .5rem;
  }
}

.details-title {
  font-size: 1.5em;  
  color: #c4b4d1;    
}

.detail-title {
  font-size: 1.5em;  
  color: #c4b4d1;
  font-weight: 800;  
}

.detail-value {
  display: flex;
  flex-direction: column;
}



.checkbox-holder {
  display: flex;
  flex-direction: row;  
  align-items: center;
}

.checkbox-icon {  
  width: 1.5rem;
  height: 1.5rem;  
  fill: #c4b4d1;
  margin-right: .5rem;
}

.checkbox-message {  
  font-size: 1.5em;  
  color: #c5b0d9;    
  text-align: center;  
  margin-top: .5rem;
}

.rarity-text-common {
  color: #9584a6;  
  font-weight: 800;
}

.rarity-text-rare {
  color: #5d83d4;  
  font-weight: 800;
}

.rarity-text-epic {
  color: #710774; 
  font-weight: 800; 
}

.rarity-text-legendary {
  color: #e08d38; 
  font-weight: 800; 
}