.main-content {
  background-image: url('https://cdn.cosmicclash.io/bchblitz/general/bch-grid.jpg');
  background-size: cover;  
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.battle-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.enemy-name {  
  font-size: 4em;
  font-weight: 800;
  color: white;
  padding: 1rem;
  margin-right: auto;  
}

.enemy-fleet-holder {
  display: flex;
  flex-direction: row;
}

.results {
  margin-top: 5rem;
  margin-bottom: 5rem;

  display: flex;
  flex-direction: column;
}

.winner-text {
  font-size: 4em;
  font-weight: 800;
  color: white;
  padding: 1rem;
}

.return-button {
  border: none;
  background-color: darkgreen;
  border-radius: 0.3rem;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-weight: 800;
  font-size: 2em;
  font-family: "TitilliumWeb-Bold";
  text-align: center;  
}

.return-button:hover {
  background-color: green;
}

.player-fleet-holder {
  display: flex;
  flex-direction: row;
}

.player-name {  
  margin-left: auto;
  font-size: 4em;
  font-weight: 800;
  color: white;
  padding: 1rem;  
}

.waiting-content {
  font-size: 3em;
  font-weight: 500;
  color: white;
  padding: 1rem;
}

.link, .link:focus, .link:visited  {
  text-decoration: none;
}

