.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.9);
}

.popup-content-image {
  background-color: var(--base-text-color-alpha-low);
  border: 3px solid var(--base-text-color-alpha);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding-left: 5rem;
  padding-right: 5rem;

  position:relative;
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
}

.cross-holder {
  position: absolute;  
  top: 1rem;
  right: 1rem;
}

.popup-title {
  font-size: 3em;  
  color: #c5b0d9;  
  font-family: 'TitilliumWeb-Bold';  
  margin-bottom: 3rem;
  
  padding-left: 2rem;
  padding-right: 2rem;
}

.popup-title-green {
  font-size: 3em;  
  color: darkgreen;  
  font-family: 'TitilliumWeb-Bold';  
  margin-bottom: 3rem;
  
  padding-left: 2rem;
  padding-right: 2rem;
}

.popup-title-red {
  font-size: 3em;  
  color: red;  
  font-family: 'TitilliumWeb-Bold';  
  margin-bottom: 3rem;
  
  padding-left: 2rem;
  padding-right: 2rem;
}

.winner-result {
  display: flex;
  flex-direction: row;
}

.winner-text {
  font-size: 2em;
  font-weight: 800;
  color: white;    
}

.hint-text {
  font-size: 1.5em;  
  color: #c5b0d9;  
  text-align: center;
  padding-left: .5rem;
  padding-right: .5rem;
}


.button-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.return-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: .5rem 1.5rem .5rem 1.5rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';  
}

.return-button:hover {
  background-color: #423f3f;
}

.return-button-log {    
  border: 1px solid white;
  padding: .5rem 1.5rem .5rem 1.5rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center; 
  font-family: 'TitilliumWeb-Bold';  
  margin-right: 1rem;
}

.return-button-log:hover {
  background-color: #423f3f;
}

.waiting-content {
  font-size: 3em;
  font-weight: 500;
  color: white;
  padding: 1rem;
}

.link, .link:focus, .link:visited  {
  text-decoration: none;
}

.medal-image {
  height: 3rem;  
}

.medal-icon {
  fill: #5d83d4;
  height: 3rem;  
}

.token-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.earn-section {
  display: flex;
  flex-direction: row;  
}

.tokens-text {
  font-size: 1em;  
  color: #c5b0d9;  
  margin-right: 1rem;
  width: 20rem;
}

.token-result {
  display: flex;
  flex-direction: row;
}

.tokens-value {
  font-size: 1em;  
  color: white;  
  margin-right: .5rem;
}

.token-image {
  height: 1rem;
}

.bonus-image {
  height: 1rem;  
  margin-top: .1rem;
  margin-right: 0.5rem;
}

.bonus-value {
  font-size: 1.5em;
  font-weight: 800;
  color: white;  
  margin-right: .5rem;
}

.bonus-text {
  font-size: 1.5em;
  font-weight: 800;
  color: #c5b0d9;  
  margin-right: 1rem;  
}

.token-image-bonus {
  height: 1.8rem;
}

.bonus-text-none {
  font-size: 1.2em;  
  color: #c5b0d9;  
  margin-right: 1rem;
}

.bonus-image-none {
  height: 1.2rem;  
  margin-top: .1rem;
  margin-right: 0.5rem;
}

.tokens-text-large {
  font-size: 1.5em;  
  font-weight: 800;
  color: #c5b0d9;  
  margin-right: 1rem;
  width: 20rem;
}

.tokens-value-large {
  font-size: 1.5em;  
  font-weight: 800;
  color: white;  
  margin-right: .5rem;
}

.token-image-large {
  height: 2rem;
}
