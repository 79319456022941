.card-holder {    
  background-color: rgba(193, 176, 211, 0.2);    
  border: 1px solid #8F73BE;
   
  margin-right: .5rem; 
  height: 15rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
}



.card-holder:last-child {
  margin-right: 0;
}

.card-holder-common {
  border: 1px solid white;  
  color: black;
}
.card-holder-uncommon {
  border: 1px solid green;  
}
.card-holder-scarce {
  border: 1px solid teal;  
}
.card-holder-rare {
  border: 1px solid blue;  
}
.card-holder-epic {
  border: 1px solid purple;  
}
.card-holder-legendary {
  border: 1px solid orange;  
}
.card-holder-mythic {
  border: 1px solid #ff66cc;  
}
.card-holder-secret {
  border: 1px solid gold;  
}


.top-row {  
  color: white;
  padding-bottom: .2rem;
  padding-top: .2rem;  
  width: 100%;
  text-align: center;
  position: relative;
}

.info-icon {
  fill: white;
  width: 1rem;
  height: 1rem;
  margin-left: .2rem;
    
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  left: 0;
}

.remove-icon {
  fill: red;
  width: 1rem;
  height: 1rem;
  margin-right: .2rem;
    
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0;
}

.top-row-common {
  background-color: white;    
  color:black;
}
.top-row-uncommon {
  background-color: green;    
}
.top-row-scarce {
  background-color: teal;    
}
.top-row-rare {
  background-color: blue;    
}
.top-row-epic {
  background-color: purple;    
}
.top-row-legendary {
  background-color: orange;    
}
.top-row-mythic {
  background-color: #ff66cc;   
  color:black; 
}
.top-row-secret {
  background-color: gold;    
  color:black;
}




.card-name {
  font-size: 0.8rem;  
  font-family: 'TitilliumWeb-Bold';
}

.middle-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image {  
  display: flex;  
  justify-content: center;
  align-items: center;
  height: 12rem;  
  padding-left: 1rem;
  padding-right: 1rem;
}

.spritesheet-table {  
  display: table;
  height: 100%;
}

.spritesheet-wrapper {  
  display: table-cell;
  vertical-align: middle;
}

.spritesheet-container {  
  overflow: hidden;
  position: relative;
  width: 14rem;
}

.bottom-row {
  position: relative;
  cursor:pointer;
}

.move-button-left {    
  cursor:pointer;
  width: 1rem;

  position: absolute;
  left: .5rem;
  bottom: 6rem;
}

.move-button-left:hover {
  color: white;
}

.move-button-right {  
  cursor:pointer;
  width: 1rem;
  transform: scaleX(-1);

  position: absolute;
  right: .5rem;
  bottom: 6rem;
}

.move-button-right:hover {
  color: white;
}

.card-icon {
  fill: white;
  width: .8rem;
  height: .8rem;
  margin-right: .2rem;
}

.button-text {
  font-size: 0.8rem;
  font-weight: 800;
  color: white;
}

.slot-empty {
  background-color: rgba(226,157,228,.2);    
  border: 1px solid rgba(186,164,222, .2);
  text-align: center;

  width: 12.5rem;
  height: 15rem;  
  margin-right: .5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.slot-empty:last-child {
  margin-right: 0;
}

.slot-empty-text {
  font-weight: 400;
  font-size: 1.5em;
  color: var(--base-text-color);  
  padding: 1rem;
  margin-top: 1rem;
}

.rocket-icon {
  fill: var(--base-text-color);
  width: 2rem;
  height: 2rem;
  padding: 1rem;
}

.rarity-select {
  position: absolute;
  bottom: .5rem;
  right: .5rem;  
}

.rarity-holder {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1em;
  color: white;
  
  font-weight: 800;
  width: 1.5rem;
  height: 1.5rem;
  padding-top: .1rem;
  text-align: center;
}

.rarity-holder-common {
  background-color: white;  
}
.rarity-holder-uncommon {
  background-color: green;  
}
.rarity-holder-scarce {
  background-color: teal;  
}
.rarity-holder-rare {
  background-color: blue;  
}
.rarity-holder-epic {
  background-color: purple;  
}
.rarity-holder-legendary {
  background-color: orange;  
}
.rarity-holder-mythic {
  background-color: #ff66cc;  
}
.rarity-holder-secret {
  background-color: gold;  
}



