.wrapper {
    background-color: var(--color-background-light-grey-3);
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;    
    color: var(--base-text-color);
}

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: space-evenly;
}

.login-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
    font-size: 1rem;    
    text-align: center;
    color: #F654FD;
    padding: 0.5rem;    
}

.logins {
    width: 100vw;

    display: flex;
    justify-content: center;
}

.login-wax-text {
  font-size: 1rem;
  margin-left: 1rem;
  pointer-events: none;
}

.checkbox-message {
  font-size: 1.5em;  
  color: #F654FD;
  
  text-align: center;  
  font-weight: 800;
  margin-top: .5rem;
}

.confirm-popup-checkbox-holder {
  display: flex;
  flex-direction: row;  
  align-items: center;  
}


.checkbox-icon {  
  width: 2rem;
  height: 2rem;
  margin-right: .5rem;
  fill: #F654FD;
}

.logo-holder {
  max-width: 100vw;
  max-height: 30vh;
}

.login-button {
  background-color: black !important;
  border: 1px solid #F654FD !important;
  padding: .5rem 4rem .5rem 4rem !important;  
  cursor:pointer !important;
  color: #F654FD !important;
  font-size: 1.5em !important;  
  font-weight: 600 !important;  
  text-align: center !important;
  font-family: 'TitilliumWeb-Bold' !important;
  text-transform: uppercase !important;
  margin-top: .5rem !important;
}

.login-button:hover {
  background-color: #423f3f !important;
}

.title-large {
  font-size: 1.5rem;    
  font-weight: 800;
  text-align: center;
  color: white;
  padding: 0.5rem;    
}

.info-button {
  background-color: black !important;
  border: 1px solid white !important;
  padding: .25rem 2rem .25rem 2rem !important;  
  cursor:pointer !important;
  color: white !important;
  font-size: .75em !important;    
  text-align: center !important;
  font-family: 'TitilliumWeb-Bold' !important;
  text-transform: uppercase !important;
  margin-top: .5rem !important;
}

.info-button:hover {
  background-color: #423f3f !important;
}

.rpc-holder {
  display: flex;
  flex-direction: row;    
  align-items: center;  
  justify-content: center;
  width: 100vw;
}

.rpc-label {
  
  font-size: 1.5em;  
  color: #F654FD;
    
  font-weight: 800;  
  text-align: right;
  margin-right: 1rem;
}

.rpc-endpoint {
  
  font-size: 1.5em;  
  color: #F654FD;  
  text-decoration: underline;
  
  text-align: left;  
  font-weight: 800;  
}

@media (orientation: portrait) {
  .rpc-holder {
    flex-direction: column;
  }

  .rpc-label {    
    text-align: center;
    margin-right: 1rem;
  }
    
  .rpc-endpoint {
    width: 100vw;    
    text-align: center;
    margin-top: .5rem;
    margin-left: 0rem;
  }
}
