.main-content {  
  display: flex;
  flex-direction: row;
  flex-grow: .1;
  color: var(--base-text-color);  
}

.center-content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;  
  width: 76rem;
}

.center-details {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;  
}

.mission-holder {
  display:flex;
  flex-direction: column;    
}

.mission-top {  
  font-size: 2em;
  
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: .5rem;
}

.battle-top {  
  color: #c4b4d1;  
  font-size: 2em;
  
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: .5rem;
  margin-top: 2rem;
}

.mission-manage-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  right: .5rem;
}

.mission-manage-button:hover {
  background-color: #6cc0ee;
}

.battle-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: #F654FD;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  left: 0;
}

.battle-button:hover {
  background-color: #423f3f;
}

.rematch-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 1rem .5rem 1rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  left: 10rem;
}

.rematch-button:hover {
  background-color: #6cc0ee;
}


.link, .link:focus, .link:visited  {
  text-decoration: none;
}

.battle-button-disabled {  
  background-color: rgba(160, 160, 160, 0.8);
  border: 1px solid #c1bfc4;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  left: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.battle-button-disabled-second {  
  background-color: rgba(160, 160, 160, 0.8);
  border: 1px solid #c1bfc4;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  left: 10rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.time-icon {
  width: 1rem;
  height: 1rem;
  fill: white;
  margin-right: .5em;
}

.mission-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.card-setups {
  display:flex;
  flex-direction: column;  
}

.card-setup-row {
  display:flex;
  flex-direction: row; 
  margin-top: 1rem; 
  align-items: center;
}

.card-list {
  display:flex;
  flex-direction: row;  
}

.card-type-box {
  display:flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  
  background-color: rgba(193, 176, 211, 0.2);    
  border: 1px solid white;
  margin-right: .5rem;  

  position: relative;
}

.card-type-title {
  color: white;    
  font-size: 2em;
  text-align: center;
}

.points-container {
  position: absolute;
  font-size: 2em;
  color: white;
  left: 1rem;
  top: 0;
}

.battle-button-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.player-stats-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
}

.token-image {
  height: 2rem;
  margin-left: .5rem;
}

.faction-hero {  
  position: absolute;
  right: 5px;
  top: 4px;

  width: 2.5rem;
  height: 2.5rem;
}

.faction-villain {
  position: absolute;
  right: 5px;
  top: 4px;

  width: 2.5rem;
  height: 2.5rem;
}

.confirm-popup {
  background-image: url('https://cdn.cosmicclash.io/general/CosmicClashBG-2 faded.jpg');
  background-size: cover;      
  width: 50rem;
  height: 30rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.confirm-popup-title {
  font-size: 3em;  
  color: #c5b0d9;  
  font-family: 'TitilliumWeb-Bold';  
  margin-bottom: 3rem;  
  margin-top: 1rem;
}

.confirm-popup-message {
  font-size: 1.5em;
  font-weight: 800;
  color: #c5b0d9;  
  padding: 1rem;
  text-align: center;
}

.confirm-popup-message-bottom {
  font-size: 1em;  
  color: #c5b0d9;  
}

.confirm-popup-button-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 1rem;
  width: 100%;
}

.popup-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 5rem .5rem 5rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'TitilliumWeb-Bold';
  margin-top: 5rem;
}

.popup-button:hover {
  background-color: #6cc0ee;
}

.popup-button-none {
  background-color: rgba(0,0,0,0);
}

.react-confirm-alert-overlay {
  background: rgba(0,0,0,0.8) !important;
}

.leaderboard-top {  
  color: #c4b4d1;  
  font-size: 2em;
  
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: .5rem;
}

.leaderboard-left {    
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
    
  position: absolute;
  bottom: 0rem;
  left: .5rem;
}

.leaderboard-right {    
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
    
  position: absolute;
  bottom: 0rem;
  right: .5rem;
}

.leaderboard-holder {
  display: flex;
  flex-direction: column;  
  align-items: center;    
  color: #c4b4d1;  
  font-size: 1em;
  
  margin-top: 2rem;
}

.bottom-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.deployment-cost-section {
  display: flex;
  flex-direction: row;    
  align-items: center;
  margin-top: .5rem;
}

.deployment-cost-title {
  font-size: 1em;  
  color: #c4b4d1;
  margin-right: .5rem;
}

.deployment-cost-title-top {
  font-size: 1em;  
  color: #c4b4d1;
  margin-right: .5rem;
  margin-top: .5rem;
}

.total-points-green {
  color: green;
  margin-left: .5rem;
  font-weight: 800;
}

.total-points-red {
  color: red;
  margin-left: .5rem;
  font-weight: 800;
}

.deployment-cost-title-token {
  font-size: 1em;  
  color: #c4b4d1;
  margin-right: .5rem;
}

.deployment-cost-token {
  height: 1.5rem;    
}

.deployment-cost-section-reset {
  display: flex;
  flex-direction: column;    
  align-items: center;
  margin-top: .5rem;
}

.deployment-reset {
  color: white;
  font-size: 1em;      
  width: 17rem;
}

.send-all-button-disabled {  
  background-color: rgba(160, 160, 160, 0.8);
  border: 1px solid #c1bfc4;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  left: 12rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.send-all-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  left: 12rem;
}

.send-all-button:hover {
  background-color: #6cc0ee;
}

.token-section-group {
  margin-top: .5rem;
}

.practice-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: #F654FD;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  right: 13rem;
}

.practice-button:hover {
  background-color: #423f3f;
}

.practice-button-home {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  right: .7rem;
}

.practice-button-home:hover {
  background-color: #6cc0ee;
}

.mission-manage-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: #F654FD;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
    
  position: absolute;
  bottom: 0rem;
  right: .5rem;
}

.mission-manage-button:hover {
  background-color: #423f3f;
}

.tournament-button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tournament-sign-up-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: #F654FD;
  
  font-size: 1.5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';        
}

.tournament-sign-up-button:hover {
  background-color: #423f3f;
}

.banner-desktop {
  margin-top: 1rem;
  cursor: pointer;
}

.leaderboard-title {
  font-size: 3rem;
  margin-bottom: .5rem;
  margin-top: -2rem;  
}

.preset-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -1rem;
  justify-content: center;
}

.preset-section {
  display: flex;
  flex-direction: row;   
  justify-content: center; 
  align-items: center;
}

.details-block {
  display: flex;
  flex-direction: column;
  
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 1px solid #c4b4d1;
  border-radius: .1rem;  

  width: 100%;
}

.details-title {
  font-size: 1.5em;  
  color: #c4b4d1;    
}

.preset-button {  
  background-color: black;
  border: 1px solid #F654FD;
  
  cursor:pointer;
  color: #F654FD;
  
  padding: .5rem .5rem .5rem .5rem;  
  font-size: .9em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';        
}

.preset-button:hover {
  background-color: #423f3f;
}

.preset-button-delete {  
  background-color: darkred;
  border: 1px solid white;
  padding: .5rem .5rem .5rem .8rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold'; 
  margin-left: .5rem;       
  margin-right: 1rem;
}

.preset-button-delete:hover {
  background-color: red;
}

.preset-selection {
  height: 2rem;
}

.preset-select {
  font: inherit;
  padding: 8px;
}

input {
  border: 1px solid #c5b0d9;   
  padding: 10px;
  background: rgba(255,255,255,0);
  margin: 0 0 0 0;
  color: #c5b0d9;
  font-size: .9em;
  font: inherit;
  margin-left: 2rem;
  margin-right: .5rem;
}

.ship-points-section {
  display: flex;
}
