.card-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-section-title {
  color: #c4b4d1;
  font-size: 2em;  
  margin-top: 5rem;
}

.player-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;   
  margin-left: -.75rem; 
  margin-right: -.75rem;
}