
.leaderboard-top-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.details-block {
  display: flex;
  flex-direction: column;
  
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 4px solid #c4b4d1;
  border-radius: .1rem;
  width: 50%;
}

.details-title {
  font-size: 1.5em;  
  color: #c4b4d1;    
}

.details-block-gold {
  display: flex;
  flex-direction: column;
  
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 4px solid #FFD700;
  border-radius: .1rem;
  width: 50%;
}

.details-title-gold {
  font-size: 1.5em;  
  color: #FFD700;    
}

.details-block-bronze {
  display: flex;
  flex-direction: column;
  
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 4px solid #cd7f32;
  border-radius: .1rem;
  width: 50%;
}

.details-title-bronze {
  font-size: 1.5em;  
  color: #cd7f32;    
}

.mode-radio-group {
  display: flex;
  flex-direction: column;  
  padding-bottom: 1rem;
}

.mode-radio-holder {
  display: flex;
  flex-direction: row;
  align-items: center;  

  margin-bottom: .5rem;
  cursor: pointer;
}

.detail-row {
  display: flex;
  flex-direction: row;
}

.detail-title {
  font-size: 1.2em;  
  color: #c4b4d1;
  font-weight: 800;  
  margin-top: .5rem;
}

.detail-title-points {
  font-size: 1.2em;  
  color: #c4b4d1;
  font-weight: 800;  
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.detail-value {
  font-size: 1em;  
  color: white;
  font-weight: 800;  
}

.detail-value-left {
  font-size: 1em;  
  color: white;
  font-weight: 800;  
  margin-left: 1rem;
}


.detail-value-red {
  font-size: 1em;  
  color: red;
  font-weight: 800;  
}

.detail-value-green {
  font-size: 1em;  
  color: green;
  font-weight: 800;  
}

.sub-heading {
  font-size: 1.2em;  
  color: white;  
  margin-top: .5rem;
  font-weight: 800;
  margin-bottom: .5rem;
}

.break {
  margin-top: .5rem;
}

.rarity-text-common {
  color: white;  
  font-weight: 800;
}
.rarity-text-uncommon {
  color: green;  
  font-weight: 800;
}.rarity-text-scarce {
  color: teal;  
  font-weight: 800;
}.rarity-text-rare {
  color: blue;  
  font-weight: 800;
}.rarity-text-epic {
  color: purple;  
  font-weight: 800;
}.rarity-text-legendary {
  color: orange;  
  font-weight: 800;
}.rarity-text-mythic {
  color: red;  
  font-weight: 800;
}.rarity-text-secret {
  color: gold;  
  font-weight: 800;
}

.token {
  height: 1rem;    
  margin-left: .5rem;
}

.fee-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}