
.leaderboard-top-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.details-block {
  display: flex;
  flex-direction: column;
  
  margin-bottom: .5rem;  

  border: 4px solid #c4b4d1;
  border-radius: .1rem;
  width: auto;
}

.details-block-gold {
  display: flex;
  flex-direction: column;
  
  margin-bottom: .5rem;  

  border: 4px solid #FFD700;
  border-radius: .1rem;
  width: auto;
}

.details-block-bronze {
  display: flex;
  flex-direction: column;
  
  margin-bottom: .5rem;  

  border: 4px solid #cd7f32;
  border-radius: .1rem;
  width: auto;
}

.details-title {
  font-size: 1.5em;  
  color: #c4b4d1;    
}

.mode-radio-group {
  display: flex;
  flex-direction: column;  
  padding-bottom: 1rem;
}

.mode-radio-holder {
  display: flex;
  flex-direction: row;
  align-items: center;  

  margin-bottom: .5rem;
  cursor: pointer;
}

.details-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dates-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.detail-title {
  font-size: 1.2em;  
  color: #c4b4d1;
  font-weight: 800;  
}

.detail-title-bold {
  font-size: 1.5em;  
  color: #c4b4d1;
  font-weight: 800;  
}


.details-title-gold {
  font-size: 1.5em;  
  color: #FFD700;    
}

.details-title-bronze {
  font-size: 1.5em;  
  color: #cd7f32;    
}

.detail-value {
  font-size: 1em;  
  color: white;  
  text-align: right;
  margin-left: auto;
}

.detail-value-red {
  font-size: 1em;  
  color: red;
  font-weight: 800;  
  text-align: right;
  margin-left: auto;
}

.detail-value-green {
  font-size: 1em;  
  color: green;
  font-weight: 800;  
  text-align: right;
  margin-left: auto;
}

@media (orientation: landscape) {
  .details-block {      
    width: 50%;
  }

  .detail-title {
    margin-right: 1rem;
  }

  .detail-value {
    margin-right: 1rem;
  }

  .detail-value-red {
    margin-right: 1rem;
  }

  .detail-value-green {
    margin-right: 1rem;
  }

  .leaderboard-top-row {
    flex-direction: row;
  }

  .details-block-bronze {
    width: 50%;
  }

  .details-block-gold {
    width: 50%;
  }
}


.rarity-text-common {
  color: #9584a6;  
  font-weight: 800;
}

.rarity-text-rare {
  color: #5d83d4;  
  font-weight: 800;
}

.rarity-text-epic {
  color: #710774; 
  font-weight: 800; 
}

.rarity-text-legendary {
  color: #e08d38; 
  font-weight: 800; 
}

.token {
  height: 1rem;    
  margin-left: .5rem;
}

.fee-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;  
  text-align: right;
  margin-left: auto;
}


@media (orientation: landscape) {
  .fee-group {
    margin-right: 1rem;
  }
}