.left-nav {
  display: flex;
  flex-direction: column;
  width: 14rem;
  margin-right: .5rem;
  margin-left: 0rem;
}

.player-block {
  display: flex;
  flex-direction: row;  

  margin-right: 1rem;
  margin-top: 1rem;
  margin-left: .5rem;
}

.nav-icon {
  width: 5rem;
  height: 5rem;  
}

.avatar-icon {
  width: 5rem;
  height: 5rem;  
  margin-top: -.5rem;
}

.token-icon {
  width: 3.5rem;
  height: 3.5rem;  
  margin-left: .8rem;
  margin-right: .7rem;
}


.nav-icon-avatar {  
  height: 4rem;  
  margin-top: -.2rem;
  margin-right: -1rem;
}

.player-info {
  display: flex;
  flex-direction: column;
  
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.player-name {
  font-size: 1em;  
  color: #c4b4d1;  
  margin-bottom: .5rem;
}

.player-stats {  
  font-size: 1em;
  color: #c4b4d1;
  
  display: flex;
  flex-direction: row;  
}

.player-stats-box {
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.token-image {
  height: 1.5rem;  
  width: 1.5rem;
  margin-right: .2rem;
}

.medal-image {
  height: 1.5rem;
  width: 1.5rem;
}

.nav-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 0rem;
  margin-left: .5rem;
  margin-bottom: .5rem;
}

.nav-block-outline {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;
  margin-right: 1rem;  
  margin-left: 1rem;
  margin-bottom: 1rem;

  color: white;
}

.nav-block-section-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 3rem;
  margin-left: 1rem;
  margin-bottom: .5rem;
}

.nav-title {
  font-size: 1.5em;
  color: #c4b4d1;
  margin-left: .5rem;
}

.nav-title-white {
  font-size: 1em;
  color: white;
  margin-left: .5rem;
}

.nav-title-active {
  font-weight: 800;
}

.nav-title:hover {  
  color: #e29de4;
}

.nav-title-bold {
  font-size: 1.2em;
  color: #c4b4d1;
  font-weight: 800;
}


.nav-title-weekly {
  font-size: 1em;
  color: #c4b4d1;
  margin-left: 3.5rem;
  margin-top: -1rem;
}

.nav-title-active-weekly {
  font-weight: 800;
}

.nav-title-weekly:hover {  
  color: #e29de4;
}

.link, .link:focus, .link:visited  {
    text-decoration: none;
}

.logout-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;

  margin-top: 5em;  
  margin-left: 2rem;  
}

.logout-icon {
  width: 2rem;
  height: 2rem;
  fill: #c4b4d1;
}

.refresh-icon {
  width: 2rem;
  height: 2rem;
  fill: #c4b4d1;  
}

.refresh-icon-white {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  fill: white;  
}

.discord-icon {
  width: 3.5rem;
  height: 3.5rem;  
  margin-left: 1rem;
  margin-right: .7rem;
  margin-top: .5rem;
}


@keyframes single-spin {
  from { transform: rotate(0deg) }
  to { transform: rotate(180deg) }
}
.refresh-icon:active {
  animation-name: single-spin;
  animation-duration: .5s;
  animation-iteration-count: 1;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: ease-in;
}

.mode-block {
  display: flex;
  flex-direction: column;
  
  
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 4px solid #c4b4d1;
  border-radius: .1rem;
}

.mode-title {
  font-size: 1.5em;
  font-weight: 800;
  color: #c4b4d1;    
}

.mode-radio-group {
  display: flex;
  flex-direction: column;  
  padding-bottom: 1rem;
}

.mode-radio-holder {
  display: flex;
  flex-direction: row;
  align-items: center;  

  margin-bottom: .5rem;
  cursor: pointer;
}

.radio-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: #c4b4d1; 
  margin-right: .5rem;  
}

.radio-icon-gold {
  width: 1.5rem;
  height: 1.5rem;
  fill: #FFD700; 
  margin-right: .5rem;  
}

.radio-icon-bronze {
  width: 1.5rem;
  height: 1.5rem;
  fill: #cd7f32; 
  margin-right: .5rem;  
}

.mode-radio-text {
  font-size: 1em;  
  color: #c4b4d1; 
}

.mode-radio-text-gold {
  font-size: 1.5em;  
  font-weight: 600;
  color: #FFD700; 
}

.mode-radio-text-bronze {
  font-size: 1.5em;
  font-weight: 600;  
  color: #cd7f32; 
}

.medal-icon {
  fill: #5d83d4;
  height: 1.5rem;
  width: 1.5rem;
}

.crafting-step {
  font-size: 1em;  
  color: #c4b4d1; 
  margin-bottom: .5rem;
}

.rank-text {
  font-size: .8em;
  color: white;
}

.orbatroid-block {
  display: flex;
  flex-direction: column;
    
  margin-left: 1rem;
    
  border: 2px solid #c4b4d1;
  border-radius: .1rem;
  cursor: pointer;
}

.orbatroid-title {
  font-size: 1em;  
  color: #c4b4d1;    
}

.orbatroid-radio-group {
  display: flex;
  flex-direction: column;  
  padding-bottom: 1rem;
  align-items: flex-start;  
}

.orbatroid-group {
  display:flex;
  flex-direction: row;
  align-items: center;
}

.orbatroid-icon {
  height: 5rem;  
}

.orbatroid-text {
  font-size: 1.2em;  
  color: purple; 
  font-weight: 800;
  margin-left: .5rem;
  text-align: center;
}

.white-text {
  color: white;
  font-weight: 800;
}

.exciting-text {
  color: orange;
  font-size: 1.4em;
  font-weight: 800;
}

.orbatroid-step {
  font-size: 1em;  
  color: #c4b4d1; 
  margin-bottom: .5rem;  
}

.orbatroid-date {
  font-size: 1em;  
  color: #c4b4d1; 
  margin-bottom: 1rem;
}

.community-icon {
  height: 5rem;  
}

.orb-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;
  
}

.orb-title {
  font-size: 1em;
  color: #c4b4d1;
  margin-left: .5rem;
}

.shake {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  1% { transform: translate(-1px, -2px) rotate(-1deg); }
  2% { transform: translate(-3px, 0px) rotate(1deg); }
  3% { transform: translate(3px, 2px) rotate(0deg); }
  4% { transform: translate(1px, -1px) rotate(1deg); }
  5% { transform: translate(-1px, 2px) rotate(-1deg); }
  6% { transform: translate(-3px, 1px) rotate(0deg); }
  7% { transform: translate(3px, 1px) rotate(-1deg); }
  8% { transform: translate(-1px, -1px) rotate(1deg); }
  9% { transform: translate(1px, 2px) rotate(0deg); }
  10% { transform: translate(1px, -2px) rotate(-1deg); }
  11% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
}

