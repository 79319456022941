.main-content {  
  display: flex;
  flex-direction: row;
  flex-grow: .1;  
}

.center-content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;  
  padding-bottom: 10rem;
  width: 76rem;
}

.sim-title {
  color: #c4b4d1;
  font-size: 2em;
  font-family: 'TitilliumWeb-Bold';
  width: 100%;
  text-align: center;
}

.sim-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
  
}

.action-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: 1em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';      
}

.action-button:hover {
  background-color: #423f3f;
}

.action-button-clear {  
  background-color: none;
  border: 1px solid white;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: 1em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';      
}

.action-button-clear:hover {
  background-color: #6cc0ee;
}

.player-holder {
  width: 76rem;
}

.cards-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 4px solid #c4b4d1;
  border-radius: .1rem;  
}

.cards-title {
  font-size: 1.5em;  
  color: #c4b4d1;    
}

.cards-holder {
  display:flex;
  flex-direction: row;
}


.button-row {
  display: flex;  
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  margin-top: .5rem;
  margin-bottom: 2rem;
  width: 100%;
}

.button-white {
  display: inline-block;
  text-align: center;
  padding: 5px 15px 2px 15px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: #00000044;
  cursor: pointer;
  transition: all ease 0.5s;
  margin-bottom: 5px;
  margin-right: 5px;
  color:white;
  font-family: 'TitilliumWeb-Bold';
}


.button-common {
  color: white;  
  border-color: #9584a6;
}

.button-rare {
  color: #00ccff;  
  border-color: #00ccff;  
}

.button-epic {
  color: magenta;  
  border-color: magenta;  
}

.button-legendary {
  color: orange;  
  border-color: orange;  
}

.sim-list {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 1rem;  
}

.button-title {
  color:white;
  font-family: 'TitilliumWeb-Bold';
}

.dropdown-section {
  display: flex;
  flex-direction: row;
}

.cards-select {
  font: inherit;
  padding: 4px;
  margin-left: .5rem;
  margin-top: -.3rem;
  background-color: transparent;
  color: white;
}

