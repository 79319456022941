.wrapper {
  height: 100vh;  
  background-color: transparent;  

  display: flex;
  justify-content: center;
  align-items: center;
}

.section {  
  background: rgba(26, 11, 52, 0.7);
  border-radius: .5rem;  
  
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  height: 20rem;

  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2+ */
}

.description {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;  
  font-size: 1.5em;
  color: white;
}


.spritesheet-container {  
  overflow: hidden;
  position: relative;
  width: 8rem;  
}

@keyframes moveSpritesheet {
  from {
      transform: translate(0px,0)
  }
  to {
      transform: translate(-100%,0)
  }
}

.spritesheet {
  animation: moveSpritesheet 1s steps(12) infinite;
  max-height: 8rem;
}

.cancel-button {  
  background-color: rgba(0,0,0,0);
  border: 1px solid white;
  padding: .5rem 5rem .5rem 5rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'TitilliumWeb-Bold';
  margin-top: 2rem;
}

.cancel-button:hover {
  background-color: #6cc0ee;
}
