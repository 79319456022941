.leaderboard-item {
  background-color: rgba(226,157,228,.2);    
  border: 1px solid #8F73BE;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: .5rem;
  margin-bottom: .5rem;    
  font-size: 1rem;

  font-weight: 800;
  width: 92%;
}

.leaderboard-item-highlight {
  background-color: rgba(19, 6, 33, 1);    
  border: 3px solid #C1B0D3;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: .5rem;
  margin-bottom: .5rem;    
  font-size: 1rem;  

  width: 92%;
}

.leaderboard-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: .5rem;
}

.leaderboard-top-row-left {
  display: flex;
  flex-direction: row;
}

.leaderboard-bottom-row {
  display: flex;
  flex-direction: column;
  gap: .2rem
}

.leaderboard-number {
  margin-right: .5rem;
}

.leaderboard-value {
  
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leaderboard-name {  
  margin-left: 0rem;
}

.leaderboard-medal-count {  
  text-align: left;
}

.medal-image {  
  width: 1rem;
  height: 1rem;    
}

.leaderboard-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaderboard-fleet {
  display: flex;
  flex-direction: row;
  height: 5rem;
  margin-right: 1rem;
}

.leaderboard-prizes {
  display: flex;
  flex-direction: row;  
  align-items: center;
  margin-top: 1rem;
  margin-left: 0rem;
}

@media (orientation: landscape) {
  .leaderboard-sections {    
    flex-direction: row;
    align-items: baseline;    
  }

  .leaderboard-prizes {
    margin-left: 2rem;
  }
}

.leaderboard-prize-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.leaderboard-prize-holder-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ship-holder-common {
  border: 1px solid #9584a6; 
  margin-right: .1rem;  
  margin-left: .1rem;  
  width: 2.5rem;
}

.ship-holder-rare {
  border: 1px solid #5d83d4;  
  margin-right: .1rem;  
  margin-left: .1rem;  
  width: 2.5rem;
}

.ship-holder-epic {
  border: 1px solid #710774;  
  margin-right: .1rem;  
  margin-left: .1rem;  
  width: 2.5rem;
}

.ship-holder-legendary {
  border: 1px solid #e08d38;  
  margin-right: .1rem;  
  margin-left: .1rem;  
  width: 2.5rem;
}

.pack-image {    
  height: 2.5rem;    
  margin-right: .1rem;
  margin-left: .1rem;
}

.ship-image {    
  height: 2.5rem;    
  margin-right: .1rem;
  margin-left: .1rem;  
}

.ship-actual-image {    
  height: 3rem;    
  margin-right: .1rem;
  margin-left: .1rem;  
  margin-top: -.8rem;
}

.leaderboard-prize-text {
  font-size: 1rem;  
  position: absolute;
  bottom: 0;
  right: 0.2rem;
  text-align: center;
  color: white;  
}

.leaderboard-prize-text-flex {
  font-size: 2rem;  
  text-align: right;
  color: white;  
  margin-right: .5rem;
}

.leaderboard-prize-text-flex-fgl {
  font-size: 1rem;  
  text-align: right;
  color: white;  
  margin-right: .5rem;
}

.fgl-image {
  width: 1rem;
  height: 1rem;
}

.pack-image-whitelist {    
  height: 2.5rem;     
  margin-left: .1rem;
  margin-right: .1rem;
}

.leaderboard-whitelist-text {
  font-size: .75rem;  
  font-weight: 800;
  position: absolute;

  text-align: center;
  color: white;  
  transform: rotate(-45deg);
}

.medal-icon {
  fill: #5d83d4;
  height: 1.5rem;
  width: 1.5rem;
}

.leaderboard-ticket-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leaderboard-ticket-text {
  font-size: 1rem;  
  text-align: center;
  color: white;  
}

.leaderboard-tiny-text {
  font-size: 0.7rem;  
  text-align: center;
  color: white;  
}

.avatar-icon {
  width: 5rem;
}

.avatar-icon-placeholder {
  width: 5.3rem;
}

.leaderboard-prize-holder-common-pack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: thin solid grey;
  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.pack-image-common {    
  height: 1.95rem;      
}

.leaderboard-prize-text-common {
  font-size: 1rem;  
  position: absolute;
  bottom: 0;
  right: 0rem;
  text-align: center;
  color: white;  
}

.faction-hero {
  margin-top: .2rem;
  
  width: 4rem;
  height: 4rem;
}

.faction-villain {
  margin-top: .2rem;
  
  width: 4rem;
  height: 4rem;
}