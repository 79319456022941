.side-drawer {
    position: fixed;
    width: 50vw;    
    height: 100%;
    right:0;
    top: 0;
    z-index: 200;
    background-color: #3d2e4d;
    padding: 1.2rem 1.6rem;
    padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* iOS 11.2+ */
    transition: transform 0.3s ease-out;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.open {
    transform: translateX(0);
}

.close {
    transform: translateX(150%);
}

.close-button {
  width: 2rem;
  height: 2rem;
  fill: #bbaccd;
  margin-top: 1rem;
  margin-bottom: 1rem;  
}

.content-column {
  width: 20rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;  
  margin-top: 2rem;
}

@media (orientation: landscape) {
  .content-column {
    margin-top: .5rem;
  }
}

.button-group {
  display: flex;
  margin-bottom: .5rem;
}

@media (orientation: landscape) {
  .button-group {
    margin-bottom: 0;
  }
}

.button-group-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: .5rem;
}

@media (orientation: landscape) {
  .button-group-bottom {
    flex-direction: row;
    margin-top: .5rem;
    align-items: center;
  }
}

.button-divider {
  display: none;
}

@media (orientation: landscape) {
  .button-divider {
    display: inline-block;
    color: white;
    font-size: 1.4rem;        
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

.ranking-label {
  font-size: 1.4rem;
  color: white;  
  text-decoration: underline;
  margin-top: 1rem;
  margin-bottom: .5rem;
}

@media (orientation: landscape) {
  .ranking-label {
    margin-top: .5rem;
  }
}

.button-label {
    font-size: 1.4rem;
    color: #bbaccd;    
    text-decoration: none;
}

.button-label:visited {
  color: #bbaccd;
  text-decoration: none;
}

.link, .link:focus, .link:visited  {
  text-decoration: none;
}


.orbatroid-block-portrait {
  display: flex;
  flex-direction: column;
    
  border: 2px solid #c4b4d1;
  border-radius: .1rem;

  width: 45vw;

  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  min-inline-size: auto;

  margin-bottom: 1rem;
}

@media (orientation: landscape) {
  .orbatroid-block-portrait {
    display:none;
  }
}

.orbatroid-title {
  font-size: 1em;  
  color: #c4b4d1;    
}

.orbatroid-radio-group {
  display: flex;
  flex-direction: column;  
  padding-bottom: 1rem;
  align-items: flex-start;
}

.orbatroid-group {
  display:flex;
  flex-direction: row;
  align-items: center;
}

.orbatroid-icon {
  height: 4rem;  
  margin-left: .5rem;  
}

.orbatroid-text {
  font-size: 1em;  
  color: purple; 
  font-weight: 800;
  margin-bottom: .5rem;
  text-align: center;
}

.orbatroid-step {
  font-size: 1em;  
  color: #c4b4d1; 
  margin-bottom: .5rem;
  text-align: center;
}

.orbatroid-date {
  font-size: 1em;  
  color: #c4b4d1; 
  margin-bottom: 1rem;
}

.community-icon {
  height: 2rem;  
}

.nav-title {
  font-size: 1em;
  color: #c4b4d1;
  margin-left: .5rem;
}

.crafting-step {
  font-size: 1em;  
  color: #c4b4d1; 
  margin-bottom: .5rem;
}

.nav-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: .5rem;
}

.orb-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  cursor: pointer;
  
}

.orb-title {
  font-size: 1em;
  color: #c4b4d1;
  margin-left: .5rem;
}

.shake {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  1% { transform: translate(-1px, -2px) rotate(-1deg); }
  2% { transform: translate(-3px, 0px) rotate(1deg); }
  3% { transform: translate(3px, 2px) rotate(0deg); }
  4% { transform: translate(1px, -1px) rotate(1deg); }
  5% { transform: translate(-1px, 2px) rotate(-1deg); }
  6% { transform: translate(-3px, 1px) rotate(0deg); }
  7% { transform: translate(3px, 1px) rotate(-1deg); }
  8% { transform: translate(-1px, -1px) rotate(1deg); }
  9% { transform: translate(1px, 2px) rotate(0deg); }
  10% { transform: translate(1px, -2px) rotate(-1deg); }
  11% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
}

.white-text {
  color: white;
  font-weight: 800;
}
