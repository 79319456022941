.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;
}

.popup-content-image {
  background-image: url('https://cdn.cosmicclash.io/general/CosmicClashBG-2 faded.jpg');
  background-size: cover;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 60rem;  
  height: 100vh;
  max-height: 30rem;

  padding-bottom: 1rem;

  position: relative;
}

.cross-icon {  
  fill: red;
  width: 2rem;
  height: 2rem;
  position:absolute;
  right: .5rem;
  top: .5rem;
}

@media (orientation: landscape) {
  .popup-content-image {
    padding-bottom: 3rem;
  }
  
  .cross-icon {  
    top: 2rem;
    right: 1rem;
  }
}

.cross-holder {
  margin-left: auto;
  margin-top: 1rem;
  margin-right: 1rem;
}

.popup-title {
  font-size: 3em;  
  color: red;  
  font-family: 'TitilliumWeb-Bold';  
  text-align: center;  
}

.message-text {
  font-size: 1.5em;  
  color: red;    
  text-align: center;
  padding: 1rem;
}

.button-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}

.return-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 4rem .5rem 4rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'TitilliumWeb-Bold';  
}

.return-button:hover {
  background-color: #6cc0ee;
}