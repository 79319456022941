.wrapper {
  height: 100vh;  
  background-color: transparent;  

  display: flex;
  justify-content: center;
  align-items: center;  
  font-weight: 600;
  color: #F654FD;
}

.section {  
  background: rgba(26, 24, 24, 0.9);
  border-radius: .5rem;  
  
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  height: 12rem;

  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2+ */
}

.description {
  margin-left: 1rem;
  margin-right: 1rem;  
  font-size: 1.5em;  
}

.description-tiny {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 1em;  
}


.spritesheet-container {  
  overflow: hidden;
  position: relative;
  width: 8rem;  
}

@keyframes moveSpritesheet {
  from {
      transform: translate(0px,0)
  }
  to {
      transform: translate(-100%,0)
  }
}

.spritesheet {
  animation: moveSpritesheet 1s steps(12) infinite;
  max-height: 8rem;
}
