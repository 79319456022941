.main-content {  
  display: flex;
  flex-direction: column;
  flex-grow: .1;
}

.top-nav-content {
  display: flex;
  flex-direction: column;

  position: relative;
}

.center-content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;  
  padding-bottom: 10rem;
}

.stats-title {
  color: #c4b4d1;
  font-size: 2em;  
  width: 100%;
  text-align: center;
}

.card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  
}