.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;
}

.popup-content-image {
  background-image: url('https://cdn.cosmicclash.io/general/CosmicClashBG-2 faded.jpg');
  background-size: cover;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  max-width: 60rem;
  height: 100vh;
  max-height: 60rem;

  padding-top: 2rem;
  padding-bottom: 2rem;

  position: relative;
}

@media (orientation: landscape) {
  .popup-content-image {      
    padding-bottom: 4rem;
  }
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
}

.cross-holder {
  position: absolute;
  top: .5rem;
  right: .5rem;
  cursor: pointer;
}

@media (orientation: landscape) {
  .cross-holder {    
    top: 3rem;
    right: 2rem;    
  }
}

.popup-title {
  font-size: 2em;  
  color: #c5b0d9;  
  font-family: 'TitilliumWeb-Bold';    
  text-align: center;
  margin-top: 1rem;
}

.message-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;  
}

.message-row {
  font-size: 1em;
  padding: .5rem;  
  color: #c5b0d9;  
  text-align: left;  
  width: 100%;  
}

.message-text {
  font-size: 1.5em;
  padding: 1rem;
  font-weight: 800;
  color: #c5b0d9;  
  text-align: center;  
}

.error-text {
  font-size: 1em;
  padding: 1rem;  
  color: white;  
  text-align: center;  
}

.button-section {
  display: flex;
  flex-direction: row; 
  justify-content: space-evenly;
  width: 100%; 
}

.return-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 4rem .5rem 4rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'TitilliumWeb-Bold';
  
}

.return-button:hover {
  background-color: #6cc0ee;
}