.battle-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  margin-left: .2rem;
  margin-right: .2rem;
  height: 5.5rem;
}

.battle-side {
  display: flex;
  flex-direction: row;  
  align-items: center;
}


.card-holder {  
  height: 5rem;  
  border:none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.card-holder-common {
  border: 2px solid white;  
}
.card-holder-uncommon {
  border: 2px solid green;  
}
.card-holder-scarce {
  border: 2px solid teal;  
}
.card-holder-rare {
  border: 2px solid blue;  
}
.card-holder-epic {
  border: 2px solid purple;  
}
.card-holder-legendary {
  border: 2px solid orange;  
}
.card-holder-mythic {
  border: 2px solid #ff66cc;  
}
.card-holder-secret {
  border: 2px solid gold;  
}


.card-holder-dead {
  border: 2px solid darkred;  
}

.card-image {
  height: 100%;
}

.card-image-dead {
  height: 100%;
  filter: grayscale(100%);
}

.cross-icon {  
  fill: rgba(146,11,11,0.5);
  width: 3.5rem;
  height: 5rem;
}

.cross-holder {
  position: absolute;
  top: 0;
  right: 0;
}

.middle-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.empty-block {
  width: 10rem;
}

.tip-text {
  font-family: "TitilliumWeb-Bold" !important;
  font-weight: 800 !important;  
}

.level-holder {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: .5em;
  color: white;
  
  font-weight: 800;
  width: 1rem;
  height: 1rem;
  padding-top: .1rem;
  text-align: center;
}

.level-holder-common {
  background-color: white;  
}
.level-holder-uncommon {
  background-color: green;  
}
.level-holder-scarce {
  background-color: teal;  
}
.level-holder-rare {
  background-color: blue;  
}
.level-holder-epic {
  background-color: purple;  
}
.level-holder-legendary {
  background-color: orange;  
}
.level-holder-mythic {
  background-color: #ff66cc;  
}
.level-holder-secret {
  background-color: gold;  
}

