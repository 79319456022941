.card-holder {
  width: 12rem;  
  background-color: rgba(226,157,228,.2);    
  border: 1px solid #8F73BE;
  margin: .5rem;  

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card-holder-common {
  border: 1px solid white;  
  color:black;
}
.card-holder-uncommon {
  border: 1px solid green;  
}
.card-holder-scarce {
  border: 1px solid teal;  
}
.card-holder-rare {
  border: 1px solid blue;  
}
.card-holder-epic {
  border: 1px solid purple;  
}
.card-holder-legendary {
  border: 1px solid orange;  
}
.card-holder-mythic {
  border: 1px solid #ff66cc;  
}
.card-holder-secret {
  border: 1px solid gold;  
}

.top-row {  
  color: white;
  padding-bottom: .2rem;
  padding-top: .2rem;  
  width: 100%;
  text-align: center;
  position: relative;
}

.info-icon {
  fill: black;
  width: 1rem;
  height: 1rem;
  margin-right: .2rem;
    
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0;
}

.top-row-common {
  background-color: white;    
  color:black;
}
.top-row-uncommon {
  background-color: green;    
}
.top-row-scarce {
  background-color: teal;    
}
.top-row-rare {
  background-color: blue;    
}
.top-row-epic {
  background-color: purple;    
}
.top-row-legendary {
  background-color: orange;    
}
.top-row-mythic {
  background-color: #ff66cc;    
  color:black;
}
.top-row-secret {
  background-color: gold;    
  color:black;
}

.card-level {
  font-size: .5rem;  
  color: white;
}

.card-name {
  font-size: 1rem;    
  font-weight: 600;
}

.card-name-small {
  font-size: .8rem;    
  font-weight: 600;
  padding-top: .2rem;
  padding-bottom: .1rem;
}

.faction-hero {  
  position: absolute;
  left: 2px;
  top: 2px;

  width: 1.5rem;
  height: 1.5rem;
}

.faction-villain {
  position: absolute;
  left: 2px;
  top: 2px;

  width: 1.5rem;
  height: 1.5rem;
}

.card-count {
  font-size: .5rem;  
  color: white;
}

.card-image {
  width: 90%;
  margin: .5rem;
}

.middle-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.5rem;
  position: relative;
  align-items: center;
  width: 100%;
}

.level-row {
  display: inline-block;
  position:relative;
  width: 100%;
  color: #c4b4d1;
  font-size: 1em;
}

.level-text-left {
  position: absolute;
  left: 1rem;  
}

.level-text-right {
  position: absolute;
  right: 1rem;  
}

.button-row {
  display: flex;
  margin-top: 1.5rem;
}

.mission-button {
  background-color: rgba(113, 7, 116, 0.4);
  border: 1px solid #aa0bae;
  padding: .4rem 3rem .2rem 3rem;
  color: white;  
  cursor: pointer;
  font-weight: 800;  
  font-family: 'TitilliumWeb-Bold';
}

.mission-button:hover {
  background-color: rgba(113, 7, 116, 0.8);
}

.mission-button-disabled {
  background-color: rgba(149, 132, 166, 0.8);
  border: 1px solid rgba(61, 46, 77, 0.4);
  padding: .4rem 3rem .2rem 3rem;
  color: white;  
  cursor:not-allowed;
  font-weight: 800;  
  font-family: 'TitilliumWeb-Bold';
}

.card-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background-color: blueviolet;
  border-radius: 0.3rem;
  padding: .25rem .5rem .25rem .5rem;
  margin-bottom: .25rem;
  cursor:pointer;
  color: white;  
}

.card-button-spacer {
  margin-right: .5rem;
}

.card-button:hover {
  background-color: #a61ead;
}

.card-button-disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background-color: lightgrey;
  border-radius: 0.3rem;
  padding: .25rem .5rem .25rem .5rem;
  margin-bottom: .25rem;
  color: black;
}

.card-icon {
  fill: white;
  width: .8rem;
  height: .8rem;
  margin-right: .2rem;
}

.card-icon-disabled {
  fill: black;
  width: .8rem;
  height: .8rem;
  margin-right: .2rem;
}

.button-text {
  font-size: 0.8rem;
  font-weight: 800;    
}