.wrapper {
  height: 100vh;  
  background-color: transparent;  
  

  display: flex;
  flex-direction: column;
  justify-content: center;      
  align-items: center;
  font-weight: 800;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.9);
}

.popup-content-image {
  background-color: var(--base-text-color-alpha-low);
  border: 3px solid var(--base-text-color-alpha);
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  max-width: 60rem;
  position: relative;

  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-right: .5rem;
  padding-left: .5rem;

  color: white;
}

.popup-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (orientation: landscape) {
  .popup-content-image {
    max-height: 100vh;
  }
  
  .popup-top-row {
    margin-top: 1rem;
  }
}

.text-collected {  
  text-align: center;
  width: 100%;

  position: absolute;
  left: 0;
  top: 1.5rem;
}


.bottom-row {
  display: flex;
  flex-direction: row;

  width: 50rem;
  margin-top: 1rem;
}

.left-column {
  display: flex;
  flex-direction: column;
  width: 50%;  
  align-items: center;
}

.ship-image {  
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 100%;  
}

.ship-name {
  font-size: 2em;
  font-weight: 800;
}

.faction-hero {  
  width: 1.5rem;
  height: 1.5rem;
}

.faction-villain {
  width: 1.5rem;
  height: 1.5rem;
}

.right-side {
  width: 50%;
  margin-left: 1rem;

  display: flex;
  flex-direction: column;
}

.stats-title {
  font-size: 1.5em;
  font-weight: 600;
}

.stats-block {
  display: flex;
  flex-direction: column;  
}

.stat-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: .5rem;
}

.stat-image {
  width: 2rem;
  height: 2rem;
  margin-right: .5rem;
  margin-top: .2rem;
}

.card-rarity {  
  font-weight: 800;  
  margin-top: .2rem;
  font-size: .8em;
}

.card-rarity-common {
  color: white;      
}
.card-rarity-uncommon {
  color: green;    
}
.card-rarity-scarce {
  color: teal;    
}
.card-rarity-rare {
  color: blue;    
}
.card-rarity-epic {
  color: purple;    
}
.card-rarity-legendary {
  color: orange;    
}
.card-rarity-mythic {
  color: #ff66cc;      
}
.card-rarity-secret {
  color: gold;      
}

.stat-details {
  font-size: 1.5em;

  display: flex;
  flex-direction: row;
  margin-top: .2rem;
}

.stat-base-text {  
  font-size: .8em;
}

.stat-upgrade-text {
  color: lightgreen;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.stat-powerup-text {
  color: #5d83d4;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.description-text-portrait {  
  font-size: 1em;
  margin-top: 1rem;
  margin-bottom: 2rem;  
  text-align: center;
  display: inline-block;
}

.description-text-landscape { 
  font-size: 1em;
  margin-top: 1rem;
  margin-bottom: 2rem;  
  width: 50%;
  text-align: center; 
  display:none;
}

@media (orientation: landscape) {
  .left-column {
    width: 25%;  
  }

  .right-side {
    width: 25%;
  }

  .description-text-portrait {  
    display:none;
  }
  
  .description-text-landscape {  
    display: inline-block;
  }
}

.tip-text {
  font-family: "TitilliumWeb-Bold" !important;
  font-weight: 800 !important;  
}

.special-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.special-title {
  text-decoration: underline;
}


