
.leaderboard-top-row {
  display: flex;
  flex-direction: row;
  width: 100%;  
}

.details-block {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 4px solid #c4b4d1;
  border-radius: .1rem;
  width: 100%;
}

.details-column {
  display: flex;
  flex-direction: column;
}

.details-title {
  font-size: 1.5em;  
  color: #c4b4d1;    
}

.detail-title {
  font-size: 1.5em;  
  color: #c4b4d1;
  font-weight: 800;  
}

.detail-value {
  display: flex;
  flex-direction: column;
}



.checkbox-holder {
  display: flex;
  flex-direction: row;  
  align-items: center;
}

.checkbox-icon {  
  width: 1.5rem;
  height: 1.5rem;  
  fill: #c4b4d1;
  margin-right: .5rem;
}

.checkbox-message {  
  font-size: 1.5em;  
  color: #c5b0d9;    
  text-align: center;    
}

.rarity-text-common {
  color: white;  
  font-weight: 800;
}
.rarity-text-uncommon {
  color: green;  
  font-weight: 800;
}
.rarity-text-scarce {
  color: teal;  
  font-weight: 800;
}
.rarity-text-rare {
  color: blue;  
  font-weight: 800;
}
.rarity-text-epic {
  color: purple;  
  font-weight: 800;
}
.rarity-text-legendary {
  color: orange;  
  font-weight: 800;
}
.rarity-text-mythic {
  color: #ff66cc;  
  font-weight: 800;
}
.rarity-text-secret {
  color: gold;  
  font-weight: 800;
}
