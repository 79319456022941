body {
  margin: 0;
  font-family: 'TitilliumWeb-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #14121d;
  background-image: url('https://cdn.cosmicclash.io/bchblitz/general/temp-bg.jpg');
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'TitilliumWeb-Regular';
  src: local('TitilliumWeb-Regular'), url(./assets/fonts/TitilliumWeb-Regular.ttf) format('TrueType');
}
@font-face {
  font-family: 'TitilliumWeb-Bold';
  src: local('TitilliumWeb-Bold'), url(./assets/fonts/TitilliumWeb-Bold.ttf) format('TrueType');
}


.react-confirm-alert-overlay {
  background: rgba(0,0,0,0.8) !important;
}

.grecaptcha-badge { visibility: hidden; }

#ual-button {
  background-color: black !important;
  border: 1px solid #F654FD !important;
  padding: .5rem 4rem .5rem 4rem !important;  
  cursor:pointer !important;
  color: #F654FD !important;
  font-size: 1.5em !important;  
  font-weight: 600 !important;  
  text-align: center !important;
  font-family: 'TitilliumWeb-Bold' !important;
  text-transform: uppercase !important;
  margin-top: .5rem !important;
}

#ual-button:hover {
  background-color: #423f3f !important;
}

@media only screen and (max-width: 480px) {
  #ual-button {
    width: 225px !important;
  }
}

:root {
  --base-text-color: #F654FD;
  --base-text-color-alpha: rgba(246, 84, 253, 0.4);
  --base-text-color-alpha-low: rgba(246, 84, 253, 0.2);
}