.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  
  width: 100vw;
  height: 100vh;
}

.popup-content-image {
  background-color: rgba(68, 34, 34, 0.4);
  border: 3px double rgba(136, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
  position:absolute;
  right: .5rem;
  top: .5rem;
}

.cross-holder {
  margin-left: auto;
  margin-top: 1rem;
  margin-right: 1rem;
}

.popup-title {
  font-size: 4em;  
  color: white;  
  font-family: 'TitilliumWeb-Bold';  
  text-align: center;
  margin-bottom: 5rem;
}

.confirm-popup-message {
  font-size: 1.5em; 
  color: #c5b0d9;    
  text-align: center;
}

.message-details-box {
  display: flex;
  flex-direction: column;
}

.message-details-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
  width: 100vw;
}

.message-details-title {
  font-size: 1em;  
  color: #c5b0d9;    
  text-align: left;
  margin-top: .3rem;
}

.message-details-value {
  font-size: 1em;  
  color: white;    
  text-align: center;
  margin-left: .5rem;
}

.message-details-value-token {
  display: flex;
  flex-direction: row;
}

.token-image {
  width: 1rem;
  height: 1rem;
  margin-left: .5rem;
  margin-top: .1rem;
}

.checkbox-message {  
  font-size: 1.5em;
  color: white;  
  padding: 1rem;
  text-align: center;  margin-top: .5rem;
}

.checkbox-warning-message {
  font-size: 1em;  
  color: darkred;    
  text-align: center;  
  margin-bottom: .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.confirm-popup-button-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 1rem;
  width: 100%;
  cursor: pointer;
}

.confirm-popup-checkbox-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.popup-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: 1rem 4rem 1rem 4rem;  
  cursor:pointer;
  color: #F654FD;
  font-size: 1.5em;  
  font-weight: 600;  
  text-align: center;
  margin-bottom: 2rem;  
  margin-top: 3rem;
  font-family: 'TitilliumWeb-Bold';
  text-transform: uppercase;
}

.popup-button:hover {
  background-color: #423f3f;
}

.popup-button-cancel {  
  background-color: rgba(0,0,0,0);
  border: 1px solid #F654FD;
  padding: 1rem 4rem 1rem 4rem;  
  cursor:pointer;
  color: #F654FD;  
  font-size: 1.5em;  
  font-weight: 600;
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';  
  margin-bottom: 2rem;  
  margin-top: 3rem;
  margin-right: 1rem;
}

.popup-button-cancel:hover {
  background-color: #423f3f;
}

.react-confirm-alert-overlay {
  background: rgba(0,0,0,0.8) !important;
}

.checkbox-icon {  
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
  margin-top: .5rem;
  fill: #F654FD;
}


