.main-content {  
  display: flex;
  flex-direction: row;
  flex-grow: .1;
}

.center-content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;    
  width: 66rem;
}