.main-content {
  display: flex;
  flex-direction: column;  
}

.top-nav-content {
  display: flex;
  flex-direction: column;

  position: relative;
}

.center-content {
  display: flex;
  flex-direction: column;  
  margin-left: 0.5rem;
  margin-right: 0.5rem;  
}

.screen-title {
  color: #c4b4d1;
  font-size: 2em;
  font-family: 'TitilliumWeb-Bold';
  text-align: center;  
  margin-bottom: .5rem;
}

.controls-section {
  display: flex;
  flex-direction: row;  
  align-items: center;
  margin-top: .5rem;  
}

.button-group {
  display: flex;
  flex-direction: column;
}

@media (orientation: landscape) {
  .button-group {  
    flex-direction: row;
    justify-content: space-evenly;
  }  
}

.battle-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: #F654FD;
  font-size: 1em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';  
  margin-bottom: .5rem;        
}

.battle-button:hover {
  background-color: #423f3f;
}

.battle-button-disabled {  
  background-color: rgba(160, 160, 160, 0.8);
  border: 1px solid #c1bfc4;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: 1em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';    
  margin-bottom: .5rem;        
    
  display: flex;
  flex-direction: row;
  align-items: center;
}

.controls-icon {  
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
}

.controls-text {
  font-size: 1rem;  
  color: #c4b4d1;  
  margin-top: .1rem;
  font-weight: 800;
}

.controls-text-disabled {
  font-size: 1rem;  
  color: white;  
  margin-top: .1rem;
  font-weight: 800;
}

.deployment-cost-section {
  display: flex;
  flex-direction: row;    
  align-items: center;
  justify-content: center;
}

.deployment-cost-title {
  font-size: 1em;  
  color: #c4b4d1;
  margin-right: .5rem;
}

.deployment-cost-title-token {
  font-size: 1em;  
  color: #c4b4d1;
  margin-right: .5rem;
}

.deployment-cost-token {
  height: 1.5rem;    
}

.center-details {
  display: flex;
  flex-direction: column;  
}

.ship-list {
  display:flex;
  flex-direction: column;  
}

@media (orientation: landscape) {
  .ship-list {    
    flex-direction: row;
  }
}

.deployment-cost-section-reset {
  display: flex;
  flex-direction: row;    
  align-items: center;  
  justify-content: space-between;
  margin-bottom: .3rem;
}

.deployment-reset {
  color: white;
  font-size: 1em;      
}

.battle-column {
  display: flex;
  flex-direction: column;
}

.deployment-cost-title-top {
  font-size: 1.5em;  
  color: #c4b4d1;  
  margin-top: .5rem;
  margin-bottom: .5rem;
  text-align: center;
}

.deployment-cost-title-top-landscape {
  font-size: 1.5em;  
  color: #c4b4d1;  
  margin-top: .5rem;
  margin-bottom: .5rem;
  text-align: center;
}

@media (orientation: landscape) {
  .deployment-cost-title-top {    
    display: none;
  }
}

@media (orientation: portrait) {
  .deployment-cost-title-top-landscape {    
    display: none;
  }
}

.total-points-green {
  color: green;
  margin-left: .5rem;
  font-weight: 800;
}

.total-points-red {
  color: red;
  margin-left: .5rem;
  font-weight: 800;
}

.tournament-button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: .5rem;
}

.tournament-sign-up-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';        
}

.tournament-sign-up-button:hover {
  background-color: #423f3f;
}

.banner-mobile-portrait {
  width: 100%;
  display:inherit;
}

.banner-mobile-landscape {
  width: 100%;
  display:none;
}

@media (orientation: landscape) {
  .banner-mobile-portrait {    
    display:none;
  }
  
  .banner-mobile-landscape {    
    display:inherit;
  }
  
}

.checkbox-holder {
  display: flex;
  flex-direction: row;  
  align-items: center;
  justify-content: center;
}

.checkbox-icon {  
  width: 1.5rem;
  height: 1.5rem;  
  fill: #c4b4d1;
  margin-right: .5rem;
}

.checkbox-message {  
  font-size: 1.5em;  
  color: #c5b0d9;    
  text-align: center;  
  margin-top: .5rem;
}


.leaderboard-title {
  font-size: 3rem;
  margin-bottom: .5rem;
  
  font-family: 'TitilliumWeb-Bold';    
}

.preset-row {
  display: flex;
  flex-direction: column;
  width: 100%;  
  justify-content: center;
}

.preset-section {
  display: flex;
  flex-direction: row;   
  justify-content: center; 
  align-items: center;
  margin-bottom: 1rem;
}

.details-block {
  display: flex;
  flex-direction: column;
  
  margin-top: 2rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  
  border: 1px solid #c4b4d1;
  border-radius: .1rem;  

  width: 100%;
}

.details-title {
  font-size: 1.5em;  
  color: #c4b4d1;    
}

.preset-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem .5rem .5rem .5rem;  
  cursor:pointer;
  color: white;
  font-size: .9em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';        
}

.preset-button:hover {
  background-color: #6cc0ee;
}

.preset-button-delete {  
  background-color: darkred;
  border: 1px solid white;
  padding: .5rem .5rem .5rem .8rem;  
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold'; 
  margin-left: .5rem;       
}

.preset-button-delete:hover {
  background-color: red;
}

.preset-selection {
  height: 2rem;
}

.preset-select {
  font: inherit;
  padding: 8px;
}

input {
  border: 1px solid #c5b0d9;   
  padding: 10px;
  background: rgba(255,255,255,0);
  margin: 0 0 0 0;
  color: #c5b0d9;
  font-size: .9em;
  font: inherit;  
  margin-right: .5rem;
}

.time-icon {
  width: 1rem;
  height: 1rem;
  fill: white;
  margin-right: .5em;
}

@media (orientation: landscape) {
  .preset-row {   
    flex-direction: row;
  }

  .preset-button-delete {
    margin-right: 2rem;
  }
}

.practice-button {  
  background-color: black;
  border: 1px solid #F654FD;
  padding: .5rem 2rem .5rem 2rem;  
  cursor:pointer;
  color: #F654FD;
  font-size: 1em;  
  text-align: center;  
  font-family: 'TitilliumWeb-Bold';          
}

.practice-button:hover {
  background-color: #423f3f;
}

.faction-hero {    
  width: 2.5rem;
  height: 2.5rem;
}

.faction-villain {
  width: 2.5rem;
  height: 2.5rem;
}

.faction-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}
