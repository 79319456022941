.main-content {  
  display: flex;
  flex-direction: column;  
}

.top-nav-content {
  display: flex;
  flex-direction: column;

  position: relative;
}

.leaderboard-holder {
  display: flex;
  flex-direction: column;  
  align-items: center;    
  color: #c4b4d1;  
  font-size: 1em;
  
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.leaderboard-top {  
  color: #c4b4d1;  
  font-size: 1.5em;

  width: 100%;
  text-align: center;  
  margin-bottom: .5rem;

  display: flex;
  flex-direction: column;
}

.leaderboard-section {
  height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.leaderboard-left {    
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;  
    
  position: absolute;
  bottom: 0rem;
  left: .5rem;
}

.leaderboard-right {    
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;    
  font-weight: 800;      
  margin-top: .2rem;
}

.version {    
  color: white;
  font-size: .5em;  
  text-align: right;    
  margin-top: .2rem;
  margin-left: auto;
}

.banner-mobile-portrait {
  width: 100%;
  display:inherit;
}

.banner-mobile-landscape {
  width: 100%;
  display:none;
}

@media (orientation: landscape) {
  .banner-mobile-portrait {    
    display:none;
  }
  
  .banner-mobile-landscape {    
    display:inherit;
  }
  
}
