.main-content {  
  display: flex;
  flex-direction: row;
  flex-grow: .1;
}

.center-content {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;  
}


.leaderboard-top {  
  color: #c4b4d1;  
  font-size: 2em;
  font-family: 'TitilliumWeb-Bold';

  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: .5rem;
}

.leaderboard-section {
  height: 100vh;
}

.leaderboard-left {    
  cursor:pointer;
  color: white;
  font-size: .5em;  
  text-align: center;    
    
  position: absolute;
  bottom: 0rem;
  left: .5rem;
}

.leaderboard-right {    
  cursor:pointer;
  color: white;
  font-size: .3em;  
  text-align: center;    
    
  position: absolute;
  bottom: -.7rem;
  right: .5rem;
}

.leaderboard-holder {
  display: flex;
  flex-direction: column;  
  align-items: center;    
  color: #c4b4d1;    

  margin-top: 2rem;
}

.version {    
  color: white;
  font-size: .5em;  
  text-align: right;  
  margin-top: .2rem;
  margin-left: auto;
}