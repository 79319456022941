.card-holder {    
  background-color: rgba(193, 176, 211, 0.2);    
  border: 1px solid #8F73BE;
  margin-bottom: .5rem;  
  
  position: relative;
}

.holder-portrait {
  display: flex;
  flex-direction: row;  
  align-items: center;  
}

.holder-landscape {
  display:none;
}

@media (orientation: landscape) {
  .holder-portrait {
    display: none;    
  }

  .holder-landscape {    
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .card-holder {
    width: 19%;
    margin-right: .5rem;    
  }
}


.battle-holder {    
  background-color: rgba(193, 176, 211, 0.2);    
  border: 1px solid #8F73BE;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-holder-common {
  border: 1px solid white;  
  color:black;
}
.card-holder-uncommon {
  border: 1px solid green;  
}
.card-holder-scarce {
  border: 1px solid teal;  
}
.card-holder-rare {
  border: 1px solid blue;  
}
.card-holder-epic {
  border: 1px solid purple;  
}
.card-holder-legendary {
  border: 1px solid orange;  
}
.card-holder-mythic {
  border: 1px solid #ff66cc;  
}
.card-holder-secret {
  border: 1px solid gold;  
}

.left-column {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 85%;  
}

.left-column-common {
  background-color: white;    
}
.left-column-uncommon {
  background-color: green;    
}
.left-column-scarce {
  background-color: teal;    
}
.left-column-rare {
  background-color: blue;    
}
.left-column-epic {
  background-color: purple;    
}
.left-column-legendary {
  background-color: orange;    
}
.left-column-mythic {
  background-color: #ff66cc;    
}
.left-column-secret {
  background-color: gold;    
}

.info-icon {
  fill: black;
  width: 1rem;
  height: 1rem;
  margin-left: .2rem;
    
  cursor: pointer;
  position: absolute;
  top: 0.1rem;
  left: 0;
}

.remove-icon {
  fill: red;
  width: 1rem;
  height: 1rem;
  margin-right: .2rem;
    
  cursor: pointer;
  position: absolute;
  top: 0.1rem;
  right: 0;
}

@media (orientation: landscape) {
  .info-icon {
    top: .3rem;
  }

  .remove-icon {
    top: .3rem;
  }
}


.card-name {
  font-size: 1.5rem;    
  font-weight: 800;  
  text-align: center;
  padding-top: 1.7rem;
  padding-bottom: 1.6rem;
}

@media (orientation: landscape) {
  .card-name {
    font-size: .8rem;          
    text-align: center;
    padding-top: .2rem;
    padding-bottom: .2rem;
  }
}

.card-image {  
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 4rem;    
}

@media (orientation: landscape) {
  .card-image {
    width: 50%;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

.bottom-row {
  position: relative;
  width: 100%;
}

.move-button-left {    
  cursor:pointer;
  width: 1rem;
  transform: rotate(90deg);
  color: black;

  position: absolute;
  left: .5rem;
  bottom: 0;
}

.move-button-left:hover {
  color: black;
}

.move-button-right {  
  cursor:pointer;
  width: 1rem;
  transform: rotate(-90deg);
  color: black;

  position: absolute;
  right: .5rem;
  bottom: 0;
}

.move-button-right:hover {
  color: black;
}

@media (orientation: landscape) {
  .move-button-left {
    transform: rotate(0deg);
    bottom: .5rem;        
  }

  .move-button-right {
    transform: rotate(180deg);   
    bottom: .5rem;                
  }
}

.card-icon {
  fill: black;
  width: .8rem;
  height: .8rem;
  margin-right: .2rem;
}

.button-text {
  font-size: 0.8rem;
  font-weight: 800;
  color: white;
}

.slot-empty {
  background-color: rgba(226,157,228,.2);    
  border: 1px solid rgba(186,164,222, .2);
  text-align: center;
  
  display: flex;
  flex-direction: row;  
  align-items: center;
  margin-bottom: .5rem;
  height: 5rem;
}

@media (orientation: landscape) {
  .slot-empty {   
    width: 19%;    
    flex-direction: column;    
    justify-content: center;
    height: auto;
    margin-right: .5rem;
  }
}

.slot-empty-left {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slot-empty-text {
  font-weight: 400;
  font-size: 1.5em;
  color: var(--base-text-color);  
  margin-bottom: .5rem;      
}

@media (orientation: landscape) {
  .slot-empty-text {
    font-size: 1rem;
  }
}

.rocket-holder {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  
  width: 4rem;
  height: 4rem;  
  padding: .5rem;
}

.rocket-icon {
  fill: var(--base-text-color);
  width: 2rem;
  height: 2rem;    
}

.choose-button {
  background-color: rgba(113, 7, 116, 0.4);
  border: 1px solid #aa0bae;
  padding: .5rem 1rem .5rem 1rem;
  color: white;  
  cursor: pointer;
  font-family: 'TitilliumWeb-Bold';
  font-weight: 800;  
  width: 10rem;
}

@media (orientation: landscape) {
  .choose-button {
    width: 8rem;
  }
}

.choose-button:hover {
  background-color: rgba(113, 7, 116, 0.8);
}

.top-row {  
  color: black;  
  width: 100%;
  text-align: center;
  position: relative;
}

.top-row-common {
  background-color: white;    
}
.top-row-uncommon {
  background-color: green;    
}
.top-row-scarce {
  background-color: teal;    
}
.top-row-rare {
  background-color: blue;    
}
.top-row-epic {
  background-color: purple;    
}
.top-row-legendary {
  background-color: orange;    
}
.top-row-mythic {
  background-color: #ff66cc;    
}
.top-row-secret {
  background-color: gold;    
}

.no-earn-group {
  position: absolute;
  bottom: 2rem;
  left: 40%;  
}

.epic-select {
  position: absolute;
  bottom: 2rem;
  left: 40%;  
}

.fgl-token {
  position: absolute;
  height: 1rem;
  width: 1rem;
  left: .5rem;
  top: .5rem;
}

.blocked-icon {
  position: absolute;
  fill: darkred;
  height: 2rem;
  width: 2rem;  
}

@media (orientation: portrait) {
  .no-earn-group {    
    left: 35%;  
    top: 0;
  }
}

.level-select {
  position: absolute;
  bottom: .5rem;
  left: 30%;  
}

.level-select-portrait {
  position: absolute;
  top: 0;
  left: 40%;  
}

.epic-select {
  position: absolute;
  bottom: .5rem;
  left: 15%;  
}

.epic-select-portrait {
  position: absolute;
  top: 0;
  left: 35%;  
}

