.wrapper {  
  background-color: transparent;  
  color: #c4b4d1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  font-weight: 800;  

  margin-right: .5rem;
  margin-bottom: .5rem;
}

.wrapper:last-child {
  margin-right: 0rem;
}

.popup-content-image {
  background-color: rgba(226,157,228,.2);    
  border: 1px solid #5d83d4;

  justify-content: flex-start;
  align-items: center;
  width: 14rem;
  height: 14rem;  

  display: flex;
  flex-direction: column;

  position: relative;
}

.top-row {  
  color: white;
  padding-bottom: .2rem;
  padding-top: .2rem;  
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 1em;
  font-weight: 400;
}

.top-row-common {
  background-color: white;    
  color:black;
}
.top-row-uncommon {
  background-color: green;    
}
.top-row-scarce {
  background-color: teal;    
}
.top-row-rare {
  background-color: blue;    
}
.top-row-epic {
  background-color: purple;    
}
.top-row-legendary {
  background-color: orange;    
}
.top-row-mythic {
  background-color: #ff66cc;    
  color:black;
}
.top-row-secret {
  background-color: gold;    
  color:black;
}


.card-section {
  display: flex;
  flex-direction: row;    
  justify-content: space-around;
  width: 100%;
}

.card-image {  
  display: flex;  
  justify-content: center;
  align-items: center;
  width: 7.2rem;      
}

.card-image-inner {    
  width: 100%;    
  margin-top: .1rem;  
}

.card-name {
  font-size: 1.2em;  
}

.right-side {
  display: flex;
  flex-direction: column;  
    
  width: 45%;  
}

.stats-title {
  font-size: 1.5em;
  font-weight: 600;
}


.stat-block {
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.stat-row-small {
  display: flex;
  flex-direction: row;
  align-items: center;  
}

.stat-image {
  width: 1.5rem;
  padding-top: .4rem;
}


.card-rarity {  
  font-weight: 800;  
  margin-top: .2rem;
  font-size: .8em;
}

.card-holder-common {
  border: 1px solid white;  
  color:black;
}
.card-holder-uncommon {
  border: 1px solid green;  
}
.card-holder-scarce {
  border: 1px solid teal;  
}
.card-holder-rare {
  border: 1px solid blue;  
}
.card-holder-epic {
  border: 1px solid purple;  
}
.card-holder-legendary {
  border: 1px solid orange;  
}
.card-holder-mythic {
  border: 1px solid #ff66cc;  
}
.card-holder-secret {
  border: 1px solid gold;  
}


.stat-details {
  font-size: 1.5em;
  margin-left: 0.5rem;

  display: flex;
  flex-direction: row;
  margin-top: .2rem;
}

.stat-details-small {
  font-size: 1em;
}

.stat-details-small-white {
  font-size: 1em;
  color: white;
}

.stat-details-small-title {  
  font-size: .8em;
}

.stat-details-small-bold {
  font-size: 1.2em;
  display: inline;  
}

.stat-base-text {
  color: white;
  font-size: .8em;
}

.stat-upgrade-text {
  color: lightgreen;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .1rem;
}

.stat-red-text {
  color: red;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.stat-powerup-text {
  color: #5d83d4;
  font-weight: 800;
  margin-left: .5rem;
  font-size: 1rem;
  margin-top: .2rem;
}

.description-text {
  font-size: 1em;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.tip-text {
  font-family: "TitilliumWeb-Bold" !important;
  font-weight: 800 !important;  
}

.slot-empty {
  background-color: rgba(226,157,228,.2);    
  border: 1px solid rgba(186,164,222, .2);
  text-align: center;

  width: 14rem;
  height: 14rem;
  margin-right: .5rem;
  margin-bottom: .5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.slot-empty:last-child {
  margin-right: 0;
}

.slot-empty-text {
  font-weight: 400;
  font-size: 1.5em;
  color: #c4b4d1;  
  padding: 1rem;
  margin-top: 1rem;
}

.rocket-icon {
  fill: #c4b4d1;
  width: 2rem;
  height: 2rem;
  padding: 1rem;
}

.second-title-row {
  display: flex;
  flex-direction: row;  
  justify-content: center;

  position: relative;
}

.move-button-left {    
  cursor:pointer;
  width: .6rem;

  position: absolute;
  left: .5rem;
  top: .1rem;
}

.move-button-left:hover {
  color: white;
}

.move-button-right {  
  cursor:pointer;
  width: .6rem;
  transform: scaleX(-1);

  position: absolute;
  right: .5rem;
  top: .1rem;
}

.move-button-right:hover {
  color: white;
}

.remove-icon {
  fill: red;
  width: 1rem;
  height: 1rem;
  margin-right: .2rem;
    
  cursor: pointer;
  position: absolute;
  bottom: 0.25rem;
  right: 0;
}


