.main-content {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100vw;
  height: 100vh;
}

.popup-content-image {
  background-image: url('https://cdn.cosmicclash.io/bchblitz/general/temp-bg.jpg');
  background-size: cover;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 75vw;
  max-width: 60rem;
  height: 40rem;
}

.cross-icon {  
  fill: #c5b0d9;
  width: 2rem;
  height: 2rem;
}

.cross-holder {
  margin-left: auto;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.popup-title {
  font-size: 3em;  
  color: #c5b0d9;  
  font-family: 'TitilliumWeb-Bold';    
}

.message-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;  
}

.message-row {
  font-size: 1.5em;
  padding: 1rem;  
  color: #c5b0d9;  
  text-align: left;  
  width: 100%;  
}

.message-text {
  font-size: 1.5em;
  padding: 1rem;
  font-weight: 800;
  color: #c5b0d9;  
  text-align: center;  
}

.error-text {
  font-size: 1em;
  padding: 1rem;  
  color: white;  
  text-align: center;  
}

.button-section {
  display: flex;
  flex-direction: row; 
  justify-content: space-evenly;
  width: 100%; 
}

.return-button {  
  background-color: #2c9fde;
  border: 1px solid white;
  padding: .5rem 4rem .5rem 4rem;  
  cursor:pointer;
  color: white;
  font-size: 1.5em;  
  text-align: center;
  margin-bottom: 2rem;
  font-family: 'TitilliumWeb-Bold';
  margin-top: 5rem;
}

.return-button:hover {
  background-color: #6cc0ee;
}