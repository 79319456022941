.card-holder {          
  margin-right: .5rem;
  width: 3rem;
  height: 4.4rem;
    
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
}

.card-holder:last-child {
  margin-right: 0;
}

.card-holder-common {
  border: 1px solid white;  
}
.card-holder-uncommon {
  border: 1px solid green;  
}
.card-holder-scarce {
  border: 1px solid teal;  
}
.card-holder-rare {
  border: 1px solid blue;  
}
.card-holder-epic {
  border: 1px solid purple;  
}
.card-holder-legendary {
  border: 1px solid orange;  
}
.card-holder-mythic {
  border: 1px solid #ff66cc;  
}
.card-holder-secret {
  border: 1px solid gold;  
}

.middle-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.card-image {
  max-width: 100%;
}

.level-holder {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: .8em;
  color: white;
  
  font-weight: 800;
  width: 1rem;
  height: 1rem;
  padding-top: .1rem;
  text-align: center;
}
